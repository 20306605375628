import { wrapRootElement as wrapRoot } from './root-wrapper';
import wrapPage from './page-wrapper';
import shouldUpdateScroll from './shouldUpdateScroll';
import onClientEntry from './onClientEntry';
import './src/global.css';

export const wrapRootElement = wrapRoot;
export const wrapPageElement = wrapPage;

export { onClientEntry, shouldUpdateScroll };
