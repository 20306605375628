import { Script } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import Modal from 'react-modal';
import ModalRoutingContext from './src/context/ModalRoutingContext';

const withoutPrefix = (path) => {
  const prefix =
    typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__;

  return path.slice(prefix ? prefix.length : 0);
};

const ComponentRenderer = (props) => {
  const pathname = React.useRef(null);
  const modalPage = React.useRef(null);
  const originPage = React.useRef(null);

  const contentRef = React.useRef();

  const { location, navigate, pageResources, modalProps } = props;

  React.useEffect(() => {
    if (location.pathname !== pathname.current) {
      pathname.current = location.pathname;
      if (get(location, 'state.modal', false)) {
        modalPage.current = props;
      } else {
        originPage.current = props;
      }
    }
  });

  React.useLayoutEffect(() => {
    if (
      location.pathname !== pathname.current &&
      get(location, 'state.modal') &&
      contentRef.current
    ) {
      // modal remains open after path change, so make sure it's scrolled to the top
      contentRef.current.scrollTop = 0;
    }
  });
  if (!pageResources) {
    return null;
  }
  const isModal = !!originPage.current && get(location, 'state.modal', false);
  const pageElement = isModal
    ? React.createElement(originPage.current.pageResources.component.default, {
        ...originPage.current,
        key: originPage.current.pageResources.page.path,
      })
    : React.createElement(pageResources.component.default, {
        ...props,
        key: pageResources.page.path,
      });

  let modalElement = null;

  if (isModal) {
    modalElement = React.createElement(pageResources.component.default, {
      ...props,
      key: pageResources.page.path,
    });
  } else if (modalPage.current) {
    modalElement = React.createElement(
      get(modalPage.current, 'pageResources.component.default'),
      {
        ...modalPage.current,
        key: get(modalPage.current, 'pageResources.page.path'),
      },
    );
  }

  return (
    <>
      {pageElement}
      <Modal
        onRequestClose={() => {
          navigate(withoutPrefix(originPage.current.location.pathname), {
            state: { noScroll: true },
          });
        }}
        contentRef={(node) => (contentRef.current = node)}
        {...modalProps}
        isOpen={!!isModal}
      >
        {modalElement ? (
          <React.Fragment key={props.location.key}>
            <ModalRoutingContext.Provider
              value={{
                modal: true,
                closeTo: originPage.current
                  ? withoutPrefix(originPage.current.location.pathname)
                  : '/',
              }}
            >
              {modalElement}
            </ModalRoutingContext.Provider>
          </React.Fragment>
        ) : null}
      </Modal>
      <Script
        strategy="idle"
        src="https://polyfill.io/v3/polyfill.min.js?features=Math.sinh%2CMath.cosh"
      />
    </>
  );
};

const wrapPageElement = ({ element, props }, opts) => {
  return (
    <>
      {element}
      <Script
        strategy="idle"
        src="https://polyfill.io/v3/polyfill.min.js?features=Math.sinh%2CMath.cosh"
      />
    </>
  );
  // const { modalProps, pageAnimation } = opts;
  // return React.createElement(ComponentRenderer, {
  //   ...props,
  //   modalProps,
  //   pageAnimation,
  // });
};

export default wrapPageElement;
